import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data, setLineChartInstance }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const config = {
            type: 'line',
            data: data,
            options: {
                responsive: true,
                scales: {
                    x: {
                        stacked: false,
                        display: true,
                        title: {
                            display: true,
                            text: 'Month',
                        },
                        grid: {
                            display: false
                        }
                    },
                    yRight: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        title: {
                            display: true,
                            text: 'Right Axis',
                        },
                        grid: {
                            drawBorder: false
                        },
                        border: {
                            display: false
                        },
                    },
                    yLeft1: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        title: {
                            display: true,
                            text: 'Left Axis',
                        },
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        border: {
                            display: false
                        },
                    },
                    yLeft2: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        title: {
                            display: true,
                            text: 'Right Axis 2',
                        },
                        grid: {
                            drawOnChartArea: false,
                        },
                        border: {
                            display: false
                        },
                        offset: true
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        align: 'end',
                        labels: {
                            boxWidth: 10,
                            borderRadius: 50,
                            padding: 40,
                        }
                    },
                    tooltip: {
                        backgroundColor: '#142140'
                    }
                }
            },
        };

        const myChart = new Chart(ctx, config);
        setLineChartInstance(myChart);

        return () => {
            myChart.destroy();
        };
    }, []);

    return <canvas ref={chartRef} width="900px" height="400px"></canvas>;
};

export default LineChart;