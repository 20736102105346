import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import * as Pages from './Pages/index';
import NavBar from './Pages/NavBar/NavBar';
import TopBar from './Pages/TopBar/TopBar';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import './global.css';

const AppRoute = () => {
    const [isInitFetched, setIsInitFetched] = useState(false);
    const [toggle, setToggle] = useState(null);
    const [prePath, setPrePath] = useState('/');

    const navigate = useNavigate();
    const location = useLocation();
    const loader = useSelector((state) => state?.loader);
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);

    let enabled_init = ['dashboard'];

    useEffect(() => {
        if (reload[0] === 'reload' && location.pathname !== '/error-404') {
            navigate('/');
            setPrePath(location.pathname);
        } else {
            if (reload[0] === 'reload' && location.pathname === '/error-404') navigate('/');
        }
    }, []);

    useEffect(() => {
        if (!isInitFetched && enabled_init?.indexOf(location?.pathname?.split('/')[2]) !== -1) {
            setPrePath(location.pathname);
            navigate('/');
        }
    }, []);


    return (
        <>
            {loader.isLoading && (
                <Box sx={{ width: "100%", height: "2px", top: 1, zIndex: 4 }}>
                    <LinearProgress value={0} valueBuffer={10} />
                </Box>
            )}
            <Box className='main_wrap'>
                <NavBar expand={toggle} />
                <Box className='main_wrapper'>
                    <TopBar expand={toggle} setIsNavExpand={setToggle} />
                    <Box className='main_content'>
                        <Routes>
                            <Route path='/' exact element={<Pages.OrgInit prePath={prePath} isFetched={(fetched) => setIsInitFetched(fetched)} />} />
                            <Route path=":id">
                                <Route path="dashboard" element={<Pages.Dashboard />} />
                            </Route>
                            <Route path="/*" element={<Navigate to='/error-404' />} />
                            <Route path="error-404" element={<Pages.ErrorPage />} />
                        </Routes>
                    </Box>
                </Box>
            </Box >
        </>
    )
};

export default AppRoute;