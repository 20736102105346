import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const BubbleChart = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext("2d");

        new Chart(ctx, {
            type: "bubble",
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                        align: "start",
                        labels: {
                            boxWidth: 10,
                            borderRadius: 50,
                            padding: 40,
                        },
                    },
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        display: false,
                        grid: {
                            display: false,
                        },
                    },
                },
                title: {
                    display: false,
                },
            },
        });
    }, []);

    return (
        <canvas
            ref={chartRef}
            style={{ height: "310px", width: "calc(100% - 200px)" }}
        />
    );
};

export default BubbleChart;
