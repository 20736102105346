import { Box, Button, IconButton, MenuItem, Modal, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from './TopBar.module.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../Component/CustomSelect/CustomSelect';
import { persistStore } from 'redux-persist';
import store from '../../Store/Store';
import CloseIcon from '@mui/icons-material/Close';

const initial_account = ['My Account', 'Switch Organization', 'Logout'];
const TopBar = ({ setIsNavExpand, expand }) => {
    const [isActive, setIsActive] = useState(false);
    const [account, setAccount] = useState(initial_account);
    const [switch_org, setSwitch_org] = useState(false);
    const [select_org, setSelect_org] = useState('');

    const navigate = useNavigate();
    const org_info = useSelector((state) => state?.org_info?.value);
    const user_role = org_info?.user_information?.userRole.includes('Super Admin') || org_info?.user_information?.userRole.includes('Admin');

    const first_name = org_info?.user_information?.First_Name;
    const role = org_info?.user_information?.userRole;

    useEffect(() => {
        if (org_info?.other_organizations?.length > 1) {
            setSelect_org(org_info?.other_organizations[0]?.OrgName);
        };
    }, [org_info]);

    const handleProperties = () => {
        setIsNavExpand(!isActive);
    };

    const handleDayLeft = (date) => {
        let getdate = moment(new Date(date));
        const targetDate = new Date(getdate);
        return moment(targetDate).diff(moment(), 'days') === 0 ? 'Expires Today' : `${moment(targetDate).diff(moment(), 'days')} Days Left`;
    };

    const handleLogout = (option) => {
        switch (option) {
            case 'My Account':
                window.open(process.env.REACT_APP_ACCOUNT, '_blank');
                break;
            case 'Go to Centilio Sign':
                window.open(process.env.REACT_APP_URL, '_blank');
                break;
            // case 'Subscription':
            //     window.open(`${process.env.REACT_APP_URL}/#/c${document?.OrgId}/priceList`, '_blank');
            //     break;
            case 'Switch Organization':
                setSwitch_org(true);
                break;
            default:
                const persistor = persistStore(store);
                axios.delete(process.env.REACT_APP_LOGOUT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        persistor.purge();
                        window.open(process.env.REACT_APP_LOGIN, '_self');
                    })
                    .catch((err) => {
                        toast.error(err.message);
                    });
                break;
        }
    };

    const handleOrgUser = (e) => {
        setSelect_org(e.target.value);
    };

    const handleSwitchOrg = () => {
        setSwitch_org(false);
        let data = org_info?.other_organizations?.find(s => s.OrgName === select_org);
        if (data) {
            const persistor = persistStore(store);
            persistor.purge();
            navigate(`${data?.OrgUrlName}/dashboard`, { state: { switch_org: true } });
            window.location.reload(true);
        }
    };

    return (
        <Box className={styles.top_wrap}>
            <Box style={{ alignItems: 'center', display: 'contents' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        style={{ cursor: 'pointer' }}
                        onClick={handleProperties}
                    >
                        {isActive ? (
                            <Box className={styles.expand} onClick={() => { setIsActive(!isActive) }}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand.png'}
                                    className={styles.expand_icon}
                                    alt=''
                                />
                            </Box>
                        ) : (
                            <Box className={styles.expand_close} onClick={() => { setIsActive(!isActive) }}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand-close.png'}
                                    className={styles.expand_close_icon}
                                    alt=''
                                />
                            </Box>)}
                    </Box>
                    <Box className={styles.search_item}>
                        <Box className={styles.search_box}>
                            <Box className={styles.search_img}>
                                <img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/search.svg'} width='12px' height='12px' alt='searchIcon' />
                            </Box>
                            <TextField
                                //   onChange={(e) => onSearch({ value: e.target.value, pageSize, pageIdx, setCheckedId })}
                                sx={{
                                    maxWidth: '300px',
                                    '& input': { fontSize: '13px', padding: '9px 10px 11px 33px' }, position: 'absolute',
                                    "& input:hover": {
                                        backgroundColor: "#e8f0fe"
                                    }
                                }}
                                className={styles.search_bar}
                                placeholder={'Search in Centilio'}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                type="search"
                                variant='standard'
                            />
                        </Box>
                    </Box>
                </Box>
                {user_role && <Box>
                    <Box className={styles.get_centilio}>Get Centilio Pro</Box>
                    <Box className={styles.days_left}>{org_info?.plan_details?.length && JSON.parse(org_info?.plan_details)?.plan_name !== 'Free' ? handleDayLeft(JSON.parse(org_info?.plan_details)?.expiry) : null}</Box>
                </Box>}
                <Box className={styles.left_container}>
                    {/* <Box className={styles.plan_wrapper}>
                        <Box className={styles.plan_name}>{org_info?.plan_details?.length ? JSON.parse(org_info?.plan_details)?.plan_name : null}</Box>
                    </Box> */}
                    <CustomSelect element={org_info?.user_information?.Profile_Id ? <img src={`${process.env.REACT_APP_PROFILE}?file_id=${org_info?.user_information?.Profile_Id}&org_id=${org_info?.default_organizations?.Id}`} className={styles.profile_image} alt='Profile_img' /> :
                        <div className={styles.profile_name}>
                            <div className={styles.profile_text}>{first_name?.charAt(0)?.toUpperCase()}</div>
                        </div>
                    }>
                        <Box style={{ width: '220px' }}>
                            <Box
                                style={{
                                    padding: '16px 30px',
                                    borderBottom: '1px solid var(--border-color)',
                                }}
                            >
                                <Box className={styles.first_name}>
                                    {first_name}
                                </Box>
                                <Box
                                    style={{
                                        color: 'var(--table-font-color)',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {role}
                                </Box>
                            </Box>
                            <Box className={styles.dropdown_text}>
                                {account?.map((option) => {
                                    return (
                                        <MenuItem
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                                    borderRadius: '6px'
                                                }
                                            }}
                                            className={styles.account_text}
                                            key={option}
                                            onClick={() => handleLogout(option)}>
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Box>
                        </Box>
                    </CustomSelect>
                </Box>
            </Box>
            <Modal
                open={switch_org}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
            >
                <Box className={styles.org_wrap}>
                    <IconButton
                        className={styles.org_close}
                        onClick={() => setSwitch_org(false)}
                    >
                        <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                    </IconButton>
                    <Box className={styles.org_container}>
                        <Box className={styles.org_textfield}>
                            Switch Organization
                        </Box>
                        <Select
                            MenuProps={{ classes: { paper: styles.select_pagination } }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": { border: 'none !important' },
                                mt: 5,
                                minWidth: 80,
                                width: '100%',
                                height: '38px',
                                borderBottom: '2px solid var(--border-color) !important',
                                fontFamily: 'var(--primary-font-family)',
                            }}
                            value={select_org ? select_org : org_info?.other_organizations[0]?.OrgName}
                            onChange={handleOrgUser}
                        >
                            {org_info?.other_organizations?.length && org_info?.other_organizations?.map((item, i) => (
                                <MenuItem className={styles.pagination_item} key={i} value={item?.OrgName}>
                                    {item?.OrgName}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant='contained' className={styles.switch_btn} onClick={() => handleSwitchOrg()}>Switch</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
};

export default TopBar;