import React from 'react';
import { Box } from '@mui/material';
import styles from './AppHeader.module.css';

const AppHeader = ({ moduleText = '' }) => {

    return (
        <Box className={styles.wrap}>
            <Box component='h3' className={styles.header_text}>{moduleText}</Box>
        </Box>
    );
};

export default AppHeader;