import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';

const BarChart = ({ data, setBarChartInstance }) => {
    const chartContainer = useRef(null);

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: {
                    responsive: true,
                    interaction: {
                        intersect: false,
                    },
                    layout: {
                        labels: {
                            padding: {
                                bottom: 30
                            }
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            group: 'group1',
                            grid: {
                                display: false,
                                drawBorder: false
                            },
                        },
                        // y: {
                        //     stacked: true,
                        //     beginAtZero: true,
                        //     border: {
                        //         display: false
                        //     },
                        // },
                        'y-axis-left-1': {
                            type: 'linear',
                            position: 'left',
                            beginAtZero: true,
                            grid: {
                                display: false,
                                drawBorder: false,
                            },
                            border: {
                                display: false
                            },
                        },
                        'y-axis-left-2': {
                            stacked: true,
                            type: 'linear',
                            position: 'right',
                            beginAtZero: true,
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false
                            },
                            border: {
                                display: false
                            },
                        },
                        'y-axis-right': {
                            stacked: true,
                            type: 'linear',
                            position: 'right',
                            beginAtZero: true,
                            grid: {
                                display: true,
                                drawBorder: false,
                                drawOnChartArea: true,
                            },
                            border: {
                                display: false
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                            align: 'end',
                            labels: {
                                boxWidth: 10,
                                borderRadius: 50,
                            },
                        },
                    },
                }
            });

            setBarChartInstance(chart);

            return () => {
                chart.destroy();
            };
        }
    }, []);

    return (
        <Box style={{ width: '600px', height: '400px' }}>
            <canvas ref={chartContainer} />
        </Box>
    );
};

export default BarChart;