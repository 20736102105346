import React, { useEffect, useRef } from 'react';
import Chart, { registerables } from 'chart.js/auto';
import { Box } from '@mui/material';

Chart.register(...registerables);

const DoughnutChart = ({ data, setChartInstance }) => {
    const chartContainer = useRef(null);

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: {
                    rotation: -90,
                    circumference: 180,
                    cutout: '90%',
                    legend: {
                        labels: {
                            fontFamily: 'Roboto',
                            boxWidth: 0,
                            fontSize: 10,
                            fontColor: '#FF6E6E'
                        },
                        display: false
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            display: false
                        },
                        x: {
                            display: false,
                            grid: {
                                display: false,
                            },
                        },
                    },
                    title: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            });

            setChartInstance(chart);

            return () => {
                chart.destroy();
            };
        }

    }, []);

    return (
        <Box style={{ padding: '1vw', float: 'left', height: '30vh' }}>
            <canvas ref={chartContainer} />
        </Box>
    )
};

export default DoughnutChart;