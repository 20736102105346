import React from 'react';
import styles from './customProgressBar.module.css';
import { Box, lighten } from '@mui/material';

const CustomProgressBar = ({ value }) => {
    // const secondHalf = value - 100

    return (
        <Box className={styles.main_progressBar} >
            <Box sx={{ backgroundColor: '#377dff', width: '20%' }}></Box>
            <Box sx={{ backgroundColor: '#377dff', opacity: 0.5, width: '20%' }}></Box>
            <Box sx={{ backgroundColor: lighten('#377dff', 0.5), width: '20%' }}></Box>
            <Box sx={{ backgroundColor: '#377dff', }}></Box>
        </Box>
    );
};

export default CustomProgressBar;
