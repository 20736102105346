import { Box, Card, CardContent, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AppHeader from "../../Component/AppHeader/AppHeader";
import styles from './DashBoard.module.css';
import Charts from "../Charts/Charts";
import DoughnutChart from "../Charts/DoughnutChart";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import BubbleChart from "../Charts/BubbleChart";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomProgressBar from "../../Component/customProgressBar/customProgressBar";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import PieChartIcon from '@mui/icons-material/PieChart';
import { DataGenerator } from "../../utils/jsVectorMapData";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world";
import "jsvectormap/dist/css/jsvectormap.css";

const lineChart = [
    { name: 'Total Revenue', value: '72,540', increase: '12.5%', from: '70,104' },
    { name: 'Leads', value: '29.40%', increase: '1.7%', from: '29.01%' },
    { name: 'Avg. Click Rate', value: '58.8%', decrease: '4.4%', from: '61.2%' },
    { name: 'PageViews', value: '92,913', increase: '0.0%', from: '2,913' },
    { name: 'Total Users', value: '72,540', increase: '12.5%', from: '70,104' },
    { name: 'Seasons', value: '29.4%', increase: '1.7%', from: '29.1%' },
    { name: 'Avg. Click Rate', value: '56.8%', decrease: '4.4%', from: '61.2%' },
    { name: 'PageViews', value: '92,913', increase: '0.0%', from: '2,913' }
];

const line_chart_data = {
    labels: ['1 May', '2 May', '3 May', '4 May', '5 May', '6 May', '7 May', '8 May', '9 May', '10 May', '11 May', '12 May', '13 May', '14 May', '15 May', '16 May', '17 May', '18 May', '19 May', '20 May', '21 May', '22 May', '23 May', '24 May', '25 May', '26 May', '27 May', '28 May', '29 May'],
    datasets: [{
        backgroundColor: '#377DFF',
        borderColor: '#377DFF',
        pointRadius: 0,
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: [25, 18, 30, 231, 235, 135, 60, 60, 60, 75, 421, 420, 484, 320, 318, 17, 15, 17, 330, 120, 120, 220, 100, 190, 175, 190, 192, 195, 175, 170, 160],
        tension: 0.4
    }],
};

const doughnutYearlyChartData = {
    datasets: [{
        data: [400, 100],
        backgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        hoverBackgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        borderWidth: 0
    }],
};

const doughnutMonthlyChartData = {
    datasets: [{
        data: [200, 500],
        backgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        hoverBackgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        borderWidth: 0
    }],
};

const doughnutdailyChartData = {
    datasets: [{
        data: [600, 300],
        backgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        hoverBackgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
        borderWidth: 0
    }],
};

const barChartDataYear = {
    labels: ['Jun 1', 'Jun 2', 'Jun 3', 'Jun 4', 'Jun 5', 'Jun 6', 'Jun 7'],
    datasets: [{
        label: 'New',
        data: [165, 259, 180, 381, 156, 155, 140],
        backgroundColor: '#377DFF',
        hoverBackgroundColor: '#377DFF',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 0',
        yAxisID: 'y-axis-left-1'
    },
    {
        label: 'Overdue',
        data: [150, 280, 145, 171, 165, 147, 168],
        backgroundColor: '#e7eaf3',
        hoverBackgroundColor: '#e7eaf3',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 1',
        yAxisID: 'y-axis-left-2',
    },
    {
        label: 'Others',
        data: [50, 180, 45, 71, 65, 47, 68],
        backgroundColor: '#00c9db',
        hoverBackgroundColor: '#00c9db',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 2',
        yAxisID: 'y-axis-right'
    },
    ]
};

const barChartDataMonth = {
    labels: ['Jun 1', 'Jun 2', 'Jun 3', 'Jun 4', 'Jun 5', 'Jun 6', 'Jun 7'],
    datasets: [{
        label: 'New',
        data: [265, 359, 280, 481, 256, 255, 240],
        backgroundColor: '#377DFF',
        hoverBackgroundColor: '#377DFF',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 0',
        yAxisID: 'y-axis-left-1'
    },
    {
        label: 'Overdue',
        data: [250, 380, 245, 271, 265, 247, 268],
        backgroundColor: '#e7eaf3',
        hoverBackgroundColor: '#e7eaf3',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 1',
        yAxisID: 'y-axis-left-2'
    },
    {
        label: 'Others',
        data: [70, 280, 35, 61, 55, 37, 58],
        backgroundColor: '#00c9db',
        hoverBackgroundColor: '#00c9db',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 2',
        yAxisID: 'y-axis-right'
    },
    ]
};

const barChartDataDay = {
    labels: ['Jun 1', 'Jun 2', 'Jun 3', 'Jun 4', 'Jun 5', 'Jun 6', 'Jun 7'],
    datasets: [{
        label: 'New',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: '#377DFF',
        hoverBackgroundColor: '#377DFF',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 0',
        yAxisID: 'y-axis-left-1',
    },
    {
        label: 'Overdue',
        data: [50, 80, 45, 71, 65, 47, 68],
        backgroundColor: '#e7eaf3',
        hoverBackgroundColor: '#e7eaf3',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 1',
        yAxisID: 'y-axis-left-2',
    },
    {
        label: 'Others',
        data: [50, 180, 45, 71, 65, 47, 68],
        backgroundColor: '#00c9db',
        hoverBackgroundColor: '#00c9db',
        borderWidth: 0,
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        stack: 'Stack 2',
        yAxisID: 'y-axis-right',
    },
    ]
};

// const lineChartDat = {
//     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//     datasets: [
//         {
//             label: 'Income',
//             data: [165, 159, 180, 281, 156, 155, 240, 261, 187, 150, 177],
//             backgroundColor: ['rgba(55, 125, 255, .5)', 'rgba(255, 255, 255, .2)'],
//             borderColor: '#377dff',
//             borderWidth: 2,
//             fill: false,
//             pointRadius: 0,
//             tension: 0.4
//         },
//         {
//             label: 'Expenses',
//             data: [128, 148, 140, 219, 186, 127, 290, 230, 278, 184, 149, 163],
//             backgroundColor: ['rgba(0, 201, 219, .5)', 'rgba(255, 255, 255, .2)'],
//             borderColor: 'rgba(54, 162, 235, 1)',
//             borderWidth: 2,
//             fill: false,
//             pointRadius: 0,
//             tension: 0.4
//         },
//     ],
// };

const lineChartDataYear = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [65, 59, 80, 81, 56, 55, 40, 41, 35, 32, 37, 39],
            fill: false,
            backgroundColor: '#377dff',
            borderColor: '#377dff',
            tension: 0.4,
            yAxisID: 'yRight',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 2',
            data: [28, 48, 40, 19, 86, 27, 90, 84, 93, 90, 99, 94],
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 1)',
            tension: 0.4,
            yAxisID: 'yLeft1',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 3',
            data: [1200, 1250, 1340, 1404, 1454, 1550, 1606, 1674, 1691, 1790, 1722, 1758],
            fill: false,
            backgroundColor: '#e7eaf3',
            borderColor: '#e7eaf3',
            tension: 0.4,
            yAxisID: 'yLeft2',
            borderWidth: 2,
            pointRadius: 0,
        },
    ],
};

const lineChartDataMonth = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [365, 159, 680, 381, 356, 215, 440, 741, 235, 332, 237, 439],
            fill: false,
            backgroundColor: '#377dff',
            borderColor: '#377dff',
            tension: 0.4,
            yAxisID: 'yRight',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 2',
            data: [128, 148, 140, 119, 186, 127, 190, 184, 193, 190, 199, 194],
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 1)',
            tension: 0.4,
            yAxisID: 'yLeft1',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 3',
            data: [2200, 2250, 2340, 2404, 2454, 2550, 2606, 2674, 2691, 2790, 2722, 2758],
            fill: false,
            backgroundColor: '#e7eaf3',
            borderColor: '#e7eaf3',
            tension: 0.4,
            yAxisID: 'yLeft2',
            borderWidth: 2,
            pointRadius: 0,
        },
    ],
};

const lineChartDataDay = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [335, 249, 470, 371, 146, 445, 230, 531, 225, 432, 427, 129],
            fill: false,
            backgroundColor: '#377dff',
            borderColor: '#377dff',
            tension: 0.4,
            yAxisID: 'yRight',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 2',
            data: [838, 858, 650, 229, 796, 437, 100, 594, 103, 100, 109, 104],
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 1)',
            tension: 0.4,
            yAxisID: 'yLeft1',
            borderWidth: 2,
            pointRadius: 0,
            borderRadious: '50%',
        },
        {
            label: 'Dataset 3',
            data: [1500, 1750, 1240, 1604, 1154, 1250, 1706, 1574, 1291, 1590, 1822, 1258],
            fill: false,
            backgroundColor: '#e7eaf3',
            borderColor: '#e7eaf3',
            tension: 0.4,
            yAxisID: 'yLeft2',
            borderWidth: 2,
            pointRadius: 0,
        },
    ],
};

const bubbleChartData = {
    datasets: [
        {
            label: 'Dataset 1',
            data: [{ x: 55, y: 65, r: 99 }],
            backgroundColor: 'rgba(55, 125, 255, 0.9)',
            borderColor: 'transparent'
        },
        {
            label: 'Dataset 2',
            data: [{ x: 33, y: 42, r: 65 }],
            backgroundColor: 'rgba(100, 0, 214, 0.8)',
            borderColor: 'transparent'
        },
        {
            label: 'Dataset 3',
            data: [{ x: 46, y: 26, r: 38 }],
            backgroundColor: '#00c9db',
            borderColor: 'transparent'
        }
    ]
};

const createData = (gross, netValue, newValue, others) => {
    return { gross, netValue, newValue, others };
};

const rows = [
    createData('Gross value', 500.71, 12.1),
    createData('Net values from sales', 980.45, 6.9),
    createData('New values from sales', 950.00, 1.5),
    createData('Others', 32, 1.9)
];

const Dashboard = () => {
    const [bar_chart, setBar_chart] = useState('year');
    const [pie_chart, setPie_chart] = useState('yearly');
    const [line_chart, setLine_chart] = useState('year');
    const [pieChartData, setPieChartData] = useState({
        datasets: [{
            data: [400, 100],
            backgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
            hoverBackgroundColor: ['#377DFF', 'rgba(55, 125, 255, .35)'],
            borderWidth: 0
        }],
    });
    const [barChartData, setBarChartData] = useState({
        labels: ['Jun 1', 'Jun 2', 'Jun 3', 'Jun 4', 'Jun 5', 'Jun 6', 'Jun 7'],
        datasets: [{
            label: 'New',
            data: [165, 259, 180, 381, 156, 155, 340],
            backgroundColor: '#377DFF',
            hoverBackgroundColor: '#377DFF',
            borderWidth: 0,
            barPercentage: 0.8,
            categoryPercentage: 0.4,
            stack: 'Stack 0',
            yAxisID: 'y-axis-left-1',
        },
        {
            label: 'Overdue',
            data: [150, 280, 145, 171, 165, 147, 168],
            backgroundColor: '#e7eaf3',
            hoverBackgroundColor: '#e7eaf3',
            borderWidth: 0,
            barPercentage: 0.8,
            categoryPercentage: 0.4,
            stack: 'Stack 1',
            yAxisID: 'y-axis-left-2',
        },
        {
            label: 'Others',
            data: [50, 180, 45, 71, 65, 47, 68],
            backgroundColor: '#00c9db',
            hoverBackgroundColor: '#00c9db',
            borderWidth: 0,
            barPercentage: 0.8,
            categoryPercentage: 0.4,
            stack: 'Stack 2',
            yAxisID: 'y-axis-right',
        },
        ]
    });
    const [lineChartData, setLineChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56, 55, 40, 41, 35, 32, 37, 39],
                fill: false,
                backgroundColor: '#377dff',
                borderColor: '#377dff',
                tension: 0.4,
                yAxisID: 'yRight',
                borderWidth: 2,
                pointRadius: 0,
                borderRadious: '50%',
            },
            {
                label: 'Dataset 2',
                data: [28, 48, 40, 19, 86, 27, 90, 84, 93, 90, 99, 94],
                fill: false,
                backgroundColor: 'rgba(54, 162, 235, 1)',
                borderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.4,
                yAxisID: 'yLeft1',
                borderWidth: 2,
                pointRadius: 0,
                borderRadious: '50%',
            },
            {
                label: 'Dataset 3',
                data: [1200, 1250, 1340, 1404, 1454, 1550, 1606, 1674, 1691, 1790, 1722, 1758],
                fill: false,
                backgroundColor: '#e7eaf3',
                borderColor: '#e7eaf3',
                tension: 0.4,
                yAxisID: 'yLeft2',
                borderWidth: 2,
                pointRadius: 0,
            },
        ],
    });
    const [chartInstance, setChartInstance] = useState(null);
    const [barChartInstance, setBarChartInstance] = useState(null);
    const [lineChartInstance, setLineChartInstance] = useState(null);
    const [isHovered, setIsHovered] = useState(null);

    useEffect(() => {
        const data = DataGenerator.generateData();

        const markers = [
            { coords: [37.0902, -95.7129], name: "United States", code: "US" },
            { coords: [56.1304, -106.3468], name: "Canada", code: "CA" },
            { coords: [55.3781, -3.4360], name: "United Kingdom", code: "UK" },
            { coords: [-25.2744, 133.7751], name: "Australia", code: "AU" },
            { coords: [20.5937, 78.9629], name: "India", code: "IN" },
        ];

        new jsVectorMap({
            selector: "#dashboard_map",
            map: "world",
            // showTooltip: true,
            legend: true,
            zoomOnScroll: false,
            // focusOn: {
            //     coords: [25, 12],
            //     scale: 1.5,
            //     animate: true,
            // },
            regionStyle: {
                initial: {
                    fill: "rgba(55, 125, 255, .3)",
                },
                hover: {
                    fill: "#377dff",
                },
            },
            backgroundColor: "#132144",
            markers: markers,
            markerStyle: {
                initial: {
                    strokeWidth: 2,
                    fill: "rgba(255,255,255,.5)",
                    stroke: "rgba(255,255,255,.5)",
                    r: 6,
                },
                hover: {
                    fill: "#fff",
                    stroke: "#fff",
                },
            },

            visualizeData: {
                scale: ["#F1F3F7", "#A7AFC6"],
                // values: data,
            },
            onRegionTooltipShow: (event, tooltip, code) => {
                if (markers.some(marker => marker.code === code)) {
                    tooltip._tooltip.classList.add('jvm-tooltip-enable');
                } else {
                    tooltip.hide();
                }
                // b._tooltip.classList.remove('jvm-tooltip-enable')
            }
        });
    }, []);

    const handlePieChart = (type, data) => {
        setPie_chart(type);
        setPieChartData(data);
        if (chartInstance) {
            chartInstance.data.datasets[0].data = data.datasets[0].data;
            chartInstance.update();
        }
    };

    const handleBarChart = (type, data) => {
        setBar_chart(type);
        setBarChartData(data);
        if (barChartInstance) {
            barChartInstance.data.datasets[0].data = data.datasets[0].data;
            barChartInstance.update();
        }
    };

    const handleLineChart = (type, data) => {
        setLine_chart(type);
        setLineChartData(data);
        if (lineChartInstance) {
            lineChartInstance.data.datasets[0].data = data.datasets[0].data;
            lineChartInstance.update();
        }
    };

    return (
        <Box className={styles.main_wrap}>
            <AppHeader moduleText='Dashboard' />
            <Box className={styles.main_body_wrap}>
                <Box className={styles.card_wrap}>
                    {lineChart.map((item, i) => (
                        <Card className={styles.card_body} key={i}>
                            <CardContent className={styles.card_content} onMouseOver={() => setIsHovered(i)} onMouseOut={() => setIsHovered(null)}>
                                <Box className={styles.card_subtitle}>{item?.name}</Box>
                                <Box className={styles.card_chart_body}>
                                    <Box className={styles.card_chart_total} sx={{ color: isHovered === i ? 'var(--primary)' : '' }}>{item.value}</Box>
                                    <Box className={styles.line_chart}>
                                        <Charts data={line_chart_data} />
                                    </Box>
                                </Box>
                                <Box className={styles.card_chart_body}>
                                    <Box className={styles.diff_success}>{item?.increase ? item?.increase : item?.decrease}</Box>
                                    <Box className={styles.diff_from}> from {item?.from}</Box>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

                <Box className={styles.card_wrap}>
                    <Card sx={{ width: '40%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Pie chart</Box>
                            <Box className={styles.button_group_wrap}>
                                <Box className={`${styles.btn_group} ${pie_chart === 'yearly' && styles.active}`} onClick={() => handlePieChart('yearly', doughnutYearlyChartData)}>
                                    <span>Year</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${pie_chart === 'monthly' && styles.active}`} onClick={() => handlePieChart('monthly', doughnutMonthlyChartData)}>
                                    <span>Month</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${pie_chart === 'daily' && styles.active}`} onClick={() => handlePieChart('daily', doughnutdailyChartData)}>
                                    <span>Day</span>
                                </Box>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box sx={{ position: 'relative' }}>
                                <Box className={styles.charts_container}>
                                    <DoughnutChart data={pieChartData} setChartInstance={(data) => setChartInstance(data)} />
                                    <Box className={styles.project_bal}>
                                        <Box className={styles.project_bal_title}>Project balance</Box>
                                        <Box className={styles.project_total}>$150,238.00</Box>
                                    </Box>
                                </Box>
                                <Box className={styles.transaction_wrap}>
                                    <Box className={styles.transaction_bal_details}>
                                        <Box className={styles.transaction_bal}>$72.46</Box>
                                        <Box className={styles.last_visit}>last transaction</Box>
                                    </Box>
                                    <Box className={styles.transaction_bal_details_}>
                                        <Box className={styles.transaction_diff}>12%</Box>
                                        <Box className={styles.last_visit}>since last visit</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: '58%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Monthly expenses</Box>
                            <Box className={styles.button_group_wrap}>
                                <Box className={`${styles.btn_group} ${bar_chart === 'year' && styles.active}`} onClick={() => handleBarChart('year', barChartDataYear)}>
                                    <span>Year</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${bar_chart === 'monthly' && styles.active}`} onClick={() => handleBarChart('monthly', barChartDataMonth)}>
                                    <span>Month</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${bar_chart === 'daily' && styles.active}`} onClick={() => handleBarChart('daily', barChartDataDay)}>
                                    <span>Day</span>
                                </Box>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box className={styles.charts_container}>
                                <BarChart data={barChartData} setBarChartInstance={(data) => setBarChartInstance(data)} />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box className={styles.card_wrap}>
                    <Card sx={{ width: '100%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Recent projects</Box>
                            <Box className={styles.button_group_wrap}>
                                <Box className={`${styles.btn_group} ${line_chart === 'year' && styles.active}`} onClick={() => handleLineChart('year', lineChartDataYear)}>
                                    <span>Year</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${line_chart === 'monthly' && styles.active}`} onClick={() => handleLineChart('monthly', lineChartDataMonth)}>
                                    <span>Month</span>
                                </Box>
                                <Box className={`${styles.btn_group} ${line_chart === 'daily' && styles.active}`} onClick={() => handleLineChart('daily', lineChartDataDay)}>
                                    <span>Day</span>
                                </Box>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box className={styles.charts_container}>
                                <LineChart data={lineChartData} setLineChartInstance={(data) => setLineChartInstance(data)} />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box className={styles.card_wrap}>
                    <Card sx={{ width: '49%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Transactions</Box>
                            <Box className={styles.button_group_wrap}>
                                <Select
                                    value="Yesterday"
                                    defaultValue={'Yesterday'}
                                    className={styles.select_day_wrap}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' },
                                    }}
                                    MenuProps={{ classes: { paper: 'day_select', border: 'none' } }}
                                >
                                    {/* {dateOption?.map((s, i) => ( */}
                                    <MenuItem className={styles.menu_wrapper} value={'Yesterday'}>
                                        Yesterday
                                    </MenuItem>
                                    {/* ))} */}
                                </Select>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box className={styles.charts_container}>
                                <BubbleChart data={bubbleChartData} />
                            </Box>
                        </CardContent>
                    </Card>

                    <Card sx={{ width: '49%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Reports overview</Box>
                            <Box className={styles.button_group_wrap}>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box className={styles.progress_wrap}>
                                <Box className={styles.progress_total}>$7,431.14 USD</Box>
                                <CustomProgressBar value={20} />
                                <Box className={styles.progress_percentage}>
                                    <span>0%</span>
                                    <span>100%</span>
                                </Box>
                                <TableContainer component={Paper} elevation={0}>
                                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.gross}</TableCell>
                                                    <TableCell>{row.netValue}</TableCell>
                                                    <TableCell>{row.newValue}</TableCell>
                                                    <TableCell>{row.others}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box className={styles.card_wrap}>
                    <Card sx={{ width: '100%' }} className={styles.card_wrap_container}>
                        <Box className={styles.group_button_wrap}>
                            <Box className={styles.btn_group_title}>Audience overview</Box>
                            <Box className={styles.button_group_wrap}>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className={styles.map_header}>
                            <Box sx={{ borderRight: '1px solid var(--border-color)' }} className={styles.map_wrapper_title}>
                                <Box><PermIdentityIcon sx={{ margin: '0px 10px', color: 'rgba(55, 125, 255)' }} /></Box>
                                <Box>
                                    <Box className={styles.map_title_text}>Users</Box>
                                    <Box className={styles.map_title_value}>34,413</Box>
                                </Box>
                            </Box>
                            <Box sx={{ borderRight: '1px solid var(--border-color)' }} className={styles.map_wrapper_title}>
                                <WatchLaterIcon sx={{ margin: '0px 10px', color: 'rgba(55, 125, 255)' }} />
                                <Box>
                                    <Box className={styles.map_title_text}>Avg. session duration</Box>
                                    <Box className={styles.map_title_value}>1m 3s</Box>
                                </Box>
                            </Box>
                            <Box sx={{ borderRight: '1px solid var(--border-color)' }} className={styles.map_wrapper_title}>
                                <BackupTableIcon sx={{ margin: '0px 10px', color: 'rgba(55, 125, 255)' }} />
                                <Box>
                                    <Box className={styles.map_title_text}>Pages/Sessions</Box>
                                    <Box className={styles.map_title_value}>1.78</Box>
                                </Box>
                            </Box>
                            <Box className={styles.map_wrapper_title}>
                                <PieChartIcon sx={{ margin: '0px 10px', color: 'rgba(55, 125, 255)' }} />
                                <Box>
                                    <Box className={styles.map_title_text}>Bounce rate</Box>
                                    <Box className={styles.map_title_value}>1.78</Box>
                                </Box>
                            </Box>
                        </Box>
                        <CardContent className={styles.card_content_chart}>
                            <Box className={styles.charts_container}>
                                <Box id="dashboard_map" style={{ width: '1200px', height: '600px' }}></Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    )
};

export default Dashboard;