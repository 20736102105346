import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';
import moment from 'moment/moment';

const Charts = ({ data }) => {
  const chartContainer = useRef(null);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
    <Box style={{
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderRight: '50px solid transparent',
      borderLeft: '50px solid transparent',
      borderTop: '60px solid #555555',
      borderBottom: '0'
    }}></Box>

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = '#142140';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);

      const tableHead = document.createElement('thead');

      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('th');
        th.style.borderWidth = 0;
        th.style.padding = 10;
        th.style.fontSize = '14px';
        th.style.fontFamily = 'inter';
        th.style.opacity = '0.8';
        const text = document.createTextNode(`${title}, ${moment(new Date()).format('YYYY')}`);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginTop = '-200px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';
        span.style.translate = '0px';
        span.style.borderRadius = '50%';
        span.style.marginRight = '5px';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = '#142140';
        tr.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.borderWidth = 0;
        td.style.position = 'relative';
        td.style.fontSize = '14px';
        td.style.fontFamily = 'inter';
        const text = document.createTextNode(body);

        const ttArrow = document.createElement('div');

        ttArrow.style.width = '0';
        ttArrow.style.height = '0';
        ttArrow.style.borderStyle = 'solid #142140';
        ttArrow.style.borderRight = '7px solid transparent';
        ttArrow.style.borderLeft = '7px solid transparent';
        ttArrow.style.borderTop = '6px solid #142140';
        ttArrow.style.borderBottom = '0';
        ttArrow.style.position = 'absolute';
        ttArrow.style.bottom = '-14px';
        ttArrow.style.left = '50%';
        ttArrow.style.translate = '-50% 0px';

        td.appendChild(ttArrow);
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.translate = '0px -80px';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler
            }
          },
          scales: {
            y: {
              display: false
            },
            x: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
          title: {
            display: false
          },
        }
      });
    }
  }, []);

  return <canvas ref={chartContainer} style={{ height: '100%', width: '100%' }} />
};

export default Charts;